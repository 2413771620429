.text-body {
  margin: 0;
  vertical-align: middle;
}

.text-font-size-h1 {
  font-size: 40px;
}
.text-font-size-h2 {
  font-size: 32px;
}
.text-font-size-h3 {
  font-size: 28px;
}
.text-font-size-body1 {
  font-size: 24px;
}
.text-font-size-body2 {
  font-size: 20px;
}
.text-font-size-body2-narrow {
  font-size: 20px;
  line-height: 22px;
}
.text-font-size-caption1 {
  font-size: 18px;
}
.text-font-size-caption1-narrow {
  font-size: 18px;
  line-height: 20px;
}
.text-font-caption2 {
  font-size: 16px;
}
.text-font-caption2-narrow {
  font-size: 16px;
  line-height: 18px;
}

.input {
  width: 100%;
  height: 100%;
  user-select: text;
  border: 4px solid #c2c9cf;
  border-radius: 12px;
  background-color: #fefdfe;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
}
.input:focus {
  border-color: rgba(112, 177, 244, 0.4);
}
.input::placeholder {
  color: #e0e4e7;
}
.input:read-only {
  background-color: #c9c4c8;
}
.input:invalid {
  border-color: #fc6560;
}

.input-modal {
  width: 100%;
  height: 100%;
  user-select: text;
  border: 4px solid #c2c9cf;
  border-radius: 12px;
  background-color: #fefdfe;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
}
.input-modal:focus {
  border-color: rgba(112, 177, 244, 0.4);
}
.input-modal::placeholder {
  color: #e0e4e7;
}
.input-modal:read-only {
  background-color: #c9c4c8;
}

.input-form {
  width: 100%;
  height: 100%;
  user-select: text;
  border: 2px solid #85929E;
  border-radius: 8px;
  background-color: transparent;
  outline: none;
  padding: 14px 16px;
  box-sizing: border-box;
}
.input-form:focus {
  border-width: 2px;
  border-color: rgba(112, 177, 244, 0.4);
}
.input-form:invalid {
  border-color: #fc6560;
}
