.flex-row-el {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.flex-col-el {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-row-center {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
}

.flex-col-center {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-basis: auto;
}

.invisible-scrollbar {
  overflow: auto;
  scroll-behavior: smooth;
}

.invisible-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.visible-scrollbar {
  overflow: auto;
  scroll-behavior: smooth;
}

.visible-scrollbar::-webkit-scrollbar {
  width: 20px;
}

.visible-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.visible-scrollbar::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}
